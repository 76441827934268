// source: pbtypes/tools/cloud_api/files.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js');
goog.object.extend(proto, google_protobuf_field_mask_pb);
var pbtypes_tools_cloud_api_data_access_pb = require('../../../pbtypes/tools/cloud_api/data_access_pb.js');
goog.object.extend(proto, pbtypes_tools_cloud_api_data_access_pb);
var pbtypes_tools_cloud_api_enums_pb = require('../../../pbtypes/tools/cloud_api/enums_pb.js');
goog.object.extend(proto, pbtypes_tools_cloud_api_enums_pb);
var pbtypes_tools_cloud_api_pagination_pb = require('../../../pbtypes/tools/cloud_api/pagination_pb.js');
goog.object.extend(proto, pbtypes_tools_cloud_api_pagination_pb);
goog.exportSymbol('proto.cloud_api.AddMediaMetadataRequest', null, global);
goog.exportSymbol('proto.cloud_api.DataFilePb', null, global);
goog.exportSymbol('proto.cloud_api.DataFileResponse', null, global);
goog.exportSymbol('proto.cloud_api.DataFiles', null, global);
goog.exportSymbol('proto.cloud_api.DownloadFilesRequest', null, global);
goog.exportSymbol('proto.cloud_api.GetFileKindsResponse', null, global);
goog.exportSymbol('proto.cloud_api.UpdateDataFileRequest', null, global);
goog.exportSymbol('proto.cloud_api.VehicleDataPostPb', null, global);
goog.exportSymbol('proto.cloud_api.VehicleDataResponsePb', null, global);
goog.exportSymbol('proto.cloud_api.VehicleDownloadFileRequest', null, global);
goog.exportSymbol('proto.cloud_api.VehicleDownloadFileRequest.FileType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.VehicleDataPostPb = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.VehicleDataPostPb, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.VehicleDataPostPb.displayName = 'proto.cloud_api.VehicleDataPostPb';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.VehicleDataResponsePb = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.VehicleDataResponsePb, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.VehicleDataResponsePb.displayName = 'proto.cloud_api.VehicleDataResponsePb';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.UpdateDataFileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.UpdateDataFileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.UpdateDataFileRequest.displayName = 'proto.cloud_api.UpdateDataFileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.DataFileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.DataFileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.DataFileResponse.displayName = 'proto.cloud_api.DataFileResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.DataFilePb = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.DataFilePb, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.DataFilePb.displayName = 'proto.cloud_api.DataFilePb';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.DataFiles = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.DataFiles.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.DataFiles, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.DataFiles.displayName = 'proto.cloud_api.DataFiles';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.GetFileKindsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.GetFileKindsResponse.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.GetFileKindsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.GetFileKindsResponse.displayName = 'proto.cloud_api.GetFileKindsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.DownloadFilesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.DownloadFilesRequest.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.DownloadFilesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.DownloadFilesRequest.displayName = 'proto.cloud_api.DownloadFilesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.AddMediaMetadataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.AddMediaMetadataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.AddMediaMetadataRequest.displayName = 'proto.cloud_api.AddMediaMetadataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.VehicleDownloadFileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.VehicleDownloadFileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.VehicleDownloadFileRequest.displayName = 'proto.cloud_api.VehicleDownloadFileRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.VehicleDataPostPb.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.VehicleDataPostPb.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.VehicleDataPostPb} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.VehicleDataPostPb.toObject = function(includeInstance, msg) {
  var f, obj = {
vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
kind: jspb.Message.getFieldWithDefault(msg, 2, ""),
sha1: jspb.Message.getFieldWithDefault(msg, 3, ""),
filename: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.VehicleDataPostPb}
 */
proto.cloud_api.VehicleDataPostPb.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.VehicleDataPostPb;
  return proto.cloud_api.VehicleDataPostPb.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.VehicleDataPostPb} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.VehicleDataPostPb}
 */
proto.cloud_api.VehicleDataPostPb.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKind(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSha1(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilename(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.VehicleDataPostPb.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.VehicleDataPostPb.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.VehicleDataPostPb} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.VehicleDataPostPb.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKind();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSha1();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.cloud_api.VehicleDataPostPb.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.VehicleDataPostPb} returns this
 */
proto.cloud_api.VehicleDataPostPb.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string kind = 2;
 * @return {string}
 */
proto.cloud_api.VehicleDataPostPb.prototype.getKind = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.VehicleDataPostPb} returns this
 */
proto.cloud_api.VehicleDataPostPb.prototype.setKind = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sha1 = 3;
 * @return {string}
 */
proto.cloud_api.VehicleDataPostPb.prototype.getSha1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.VehicleDataPostPb} returns this
 */
proto.cloud_api.VehicleDataPostPb.prototype.setSha1 = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string filename = 4;
 * @return {string}
 */
proto.cloud_api.VehicleDataPostPb.prototype.getFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.VehicleDataPostPb} returns this
 */
proto.cloud_api.VehicleDataPostPb.prototype.setFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.VehicleDataResponsePb.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.VehicleDataResponsePb.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.VehicleDataResponsePb} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.VehicleDataResponsePb.toObject = function(includeInstance, msg) {
  var f, obj = {
dataFile: (f = msg.getDataFile()) && proto.cloud_api.DataFilePb.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.VehicleDataResponsePb}
 */
proto.cloud_api.VehicleDataResponsePb.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.VehicleDataResponsePb;
  return proto.cloud_api.VehicleDataResponsePb.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.VehicleDataResponsePb} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.VehicleDataResponsePb}
 */
proto.cloud_api.VehicleDataResponsePb.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cloud_api.DataFilePb;
      reader.readMessage(value,proto.cloud_api.DataFilePb.deserializeBinaryFromReader);
      msg.setDataFile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.VehicleDataResponsePb.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.VehicleDataResponsePb.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.VehicleDataResponsePb} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.VehicleDataResponsePb.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataFile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cloud_api.DataFilePb.serializeBinaryToWriter
    );
  }
};


/**
 * optional DataFilePb data_file = 1;
 * @return {?proto.cloud_api.DataFilePb}
 */
proto.cloud_api.VehicleDataResponsePb.prototype.getDataFile = function() {
  return /** @type{?proto.cloud_api.DataFilePb} */ (
    jspb.Message.getWrapperField(this, proto.cloud_api.DataFilePb, 1));
};


/**
 * @param {?proto.cloud_api.DataFilePb|undefined} value
 * @return {!proto.cloud_api.VehicleDataResponsePb} returns this
*/
proto.cloud_api.VehicleDataResponsePb.prototype.setDataFile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.VehicleDataResponsePb} returns this
 */
proto.cloud_api.VehicleDataResponsePb.prototype.clearDataFile = function() {
  return this.setDataFile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.VehicleDataResponsePb.prototype.hasDataFile = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.UpdateDataFileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.UpdateDataFileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.UpdateDataFileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.UpdateDataFileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
dataFile: (f = msg.getDataFile()) && proto.cloud_api.DataFilePb.toObject(includeInstance, f),
filename: jspb.Message.getFieldWithDefault(msg, 2, ""),
updateMask: (f = msg.getUpdateMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f),
createMultipartUploadRequest: (f = msg.getCreateMultipartUploadRequest()) && pbtypes_tools_cloud_api_data_access_pb.CreateMultipartUploadRequest.toObject(includeInstance, f),
createStubFlightIfNotExists: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.UpdateDataFileRequest}
 */
proto.cloud_api.UpdateDataFileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.UpdateDataFileRequest;
  return proto.cloud_api.UpdateDataFileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.UpdateDataFileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.UpdateDataFileRequest}
 */
proto.cloud_api.UpdateDataFileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cloud_api.DataFilePb;
      reader.readMessage(value,proto.cloud_api.DataFilePb.deserializeBinaryFromReader);
      msg.setDataFile(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilename(value);
      break;
    case 3:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setUpdateMask(value);
      break;
    case 4:
      var value = new pbtypes_tools_cloud_api_data_access_pb.CreateMultipartUploadRequest;
      reader.readMessage(value,pbtypes_tools_cloud_api_data_access_pb.CreateMultipartUploadRequest.deserializeBinaryFromReader);
      msg.setCreateMultipartUploadRequest(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCreateStubFlightIfNotExists(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.UpdateDataFileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.UpdateDataFileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.UpdateDataFileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.UpdateDataFileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataFile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cloud_api.DataFilePb.serializeBinaryToWriter
    );
  }
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUpdateMask();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
  f = message.getCreateMultipartUploadRequest();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      pbtypes_tools_cloud_api_data_access_pb.CreateMultipartUploadRequest.serializeBinaryToWriter
    );
  }
  f = message.getCreateStubFlightIfNotExists();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional DataFilePb data_file = 1;
 * @return {?proto.cloud_api.DataFilePb}
 */
proto.cloud_api.UpdateDataFileRequest.prototype.getDataFile = function() {
  return /** @type{?proto.cloud_api.DataFilePb} */ (
    jspb.Message.getWrapperField(this, proto.cloud_api.DataFilePb, 1));
};


/**
 * @param {?proto.cloud_api.DataFilePb|undefined} value
 * @return {!proto.cloud_api.UpdateDataFileRequest} returns this
*/
proto.cloud_api.UpdateDataFileRequest.prototype.setDataFile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.UpdateDataFileRequest} returns this
 */
proto.cloud_api.UpdateDataFileRequest.prototype.clearDataFile = function() {
  return this.setDataFile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.UpdateDataFileRequest.prototype.hasDataFile = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string filename = 2;
 * @return {string}
 */
proto.cloud_api.UpdateDataFileRequest.prototype.getFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.UpdateDataFileRequest} returns this
 */
proto.cloud_api.UpdateDataFileRequest.prototype.setFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.FieldMask update_mask = 3;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.cloud_api.UpdateDataFileRequest.prototype.getUpdateMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 3));
};


/**
 * @param {?proto.google.protobuf.FieldMask|undefined} value
 * @return {!proto.cloud_api.UpdateDataFileRequest} returns this
*/
proto.cloud_api.UpdateDataFileRequest.prototype.setUpdateMask = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.UpdateDataFileRequest} returns this
 */
proto.cloud_api.UpdateDataFileRequest.prototype.clearUpdateMask = function() {
  return this.setUpdateMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.UpdateDataFileRequest.prototype.hasUpdateMask = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional CreateMultipartUploadRequest create_multipart_upload_request = 4;
 * @return {?proto.cloud_api.CreateMultipartUploadRequest}
 */
proto.cloud_api.UpdateDataFileRequest.prototype.getCreateMultipartUploadRequest = function() {
  return /** @type{?proto.cloud_api.CreateMultipartUploadRequest} */ (
    jspb.Message.getWrapperField(this, pbtypes_tools_cloud_api_data_access_pb.CreateMultipartUploadRequest, 4));
};


/**
 * @param {?proto.cloud_api.CreateMultipartUploadRequest|undefined} value
 * @return {!proto.cloud_api.UpdateDataFileRequest} returns this
*/
proto.cloud_api.UpdateDataFileRequest.prototype.setCreateMultipartUploadRequest = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.UpdateDataFileRequest} returns this
 */
proto.cloud_api.UpdateDataFileRequest.prototype.clearCreateMultipartUploadRequest = function() {
  return this.setCreateMultipartUploadRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.UpdateDataFileRequest.prototype.hasCreateMultipartUploadRequest = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool create_stub_flight_if_not_exists = 5;
 * @return {boolean}
 */
proto.cloud_api.UpdateDataFileRequest.prototype.getCreateStubFlightIfNotExists = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.UpdateDataFileRequest} returns this
 */
proto.cloud_api.UpdateDataFileRequest.prototype.setCreateStubFlightIfNotExists = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.DataFileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.DataFileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.DataFileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.DataFileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
dataFile: (f = msg.getDataFile()) && proto.cloud_api.DataFilePb.toObject(includeInstance, f),
downloadUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
credentials: (f = msg.getCredentials()) && pbtypes_tools_cloud_api_data_access_pb.StsTemporaryCredentials.toObject(includeInstance, f),
createMultipartUploadResponse: (f = msg.getCreateMultipartUploadResponse()) && pbtypes_tools_cloud_api_data_access_pb.CreateMultipartUploadResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.DataFileResponse}
 */
proto.cloud_api.DataFileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.DataFileResponse;
  return proto.cloud_api.DataFileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.DataFileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.DataFileResponse}
 */
proto.cloud_api.DataFileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cloud_api.DataFilePb;
      reader.readMessage(value,proto.cloud_api.DataFilePb.deserializeBinaryFromReader);
      msg.setDataFile(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDownloadUrl(value);
      break;
    case 3:
      var value = new pbtypes_tools_cloud_api_data_access_pb.StsTemporaryCredentials;
      reader.readMessage(value,pbtypes_tools_cloud_api_data_access_pb.StsTemporaryCredentials.deserializeBinaryFromReader);
      msg.setCredentials(value);
      break;
    case 4:
      var value = new pbtypes_tools_cloud_api_data_access_pb.CreateMultipartUploadResponse;
      reader.readMessage(value,pbtypes_tools_cloud_api_data_access_pb.CreateMultipartUploadResponse.deserializeBinaryFromReader);
      msg.setCreateMultipartUploadResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.DataFileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.DataFileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.DataFileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.DataFileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataFile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cloud_api.DataFilePb.serializeBinaryToWriter
    );
  }
  f = message.getDownloadUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCredentials();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      pbtypes_tools_cloud_api_data_access_pb.StsTemporaryCredentials.serializeBinaryToWriter
    );
  }
  f = message.getCreateMultipartUploadResponse();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      pbtypes_tools_cloud_api_data_access_pb.CreateMultipartUploadResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional DataFilePb data_file = 1;
 * @return {?proto.cloud_api.DataFilePb}
 */
proto.cloud_api.DataFileResponse.prototype.getDataFile = function() {
  return /** @type{?proto.cloud_api.DataFilePb} */ (
    jspb.Message.getWrapperField(this, proto.cloud_api.DataFilePb, 1));
};


/**
 * @param {?proto.cloud_api.DataFilePb|undefined} value
 * @return {!proto.cloud_api.DataFileResponse} returns this
*/
proto.cloud_api.DataFileResponse.prototype.setDataFile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.DataFileResponse} returns this
 */
proto.cloud_api.DataFileResponse.prototype.clearDataFile = function() {
  return this.setDataFile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.DataFileResponse.prototype.hasDataFile = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string download_url = 2;
 * @return {string}
 */
proto.cloud_api.DataFileResponse.prototype.getDownloadUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.DataFileResponse} returns this
 */
proto.cloud_api.DataFileResponse.prototype.setDownloadUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional StsTemporaryCredentials credentials = 3;
 * @return {?proto.cloud_api.StsTemporaryCredentials}
 */
proto.cloud_api.DataFileResponse.prototype.getCredentials = function() {
  return /** @type{?proto.cloud_api.StsTemporaryCredentials} */ (
    jspb.Message.getWrapperField(this, pbtypes_tools_cloud_api_data_access_pb.StsTemporaryCredentials, 3));
};


/**
 * @param {?proto.cloud_api.StsTemporaryCredentials|undefined} value
 * @return {!proto.cloud_api.DataFileResponse} returns this
*/
proto.cloud_api.DataFileResponse.prototype.setCredentials = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.DataFileResponse} returns this
 */
proto.cloud_api.DataFileResponse.prototype.clearCredentials = function() {
  return this.setCredentials(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.DataFileResponse.prototype.hasCredentials = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional CreateMultipartUploadResponse create_multipart_upload_response = 4;
 * @return {?proto.cloud_api.CreateMultipartUploadResponse}
 */
proto.cloud_api.DataFileResponse.prototype.getCreateMultipartUploadResponse = function() {
  return /** @type{?proto.cloud_api.CreateMultipartUploadResponse} */ (
    jspb.Message.getWrapperField(this, pbtypes_tools_cloud_api_data_access_pb.CreateMultipartUploadResponse, 4));
};


/**
 * @param {?proto.cloud_api.CreateMultipartUploadResponse|undefined} value
 * @return {!proto.cloud_api.DataFileResponse} returns this
*/
proto.cloud_api.DataFileResponse.prototype.setCreateMultipartUploadResponse = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.DataFileResponse} returns this
 */
proto.cloud_api.DataFileResponse.prototype.clearCreateMultipartUploadResponse = function() {
  return this.setCreateMultipartUploadResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.DataFileResponse.prototype.hasCreateMultipartUploadResponse = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.DataFilePb.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.DataFilePb.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.DataFilePb} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.DataFilePb.toObject = function(includeInstance, msg) {
  var f, obj = {
uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
created: jspb.Message.getFieldWithDefault(msg, 14, 0),
kind: jspb.Message.getFieldWithDefault(msg, 2, ""),
sha1: jspb.Message.getFieldWithDefault(msg, 3, ""),
uploadUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
bucket: jspb.Message.getFieldWithDefault(msg, 5, ""),
key: jspb.Message.getFieldWithDefault(msg, 6, ""),
filename: jspb.Message.getFieldWithDefault(msg, 19, ""),
signature: jspb.Message.getFieldWithDefault(msg, 7, ""),
size: jspb.Message.getFieldWithDefault(msg, 8, 0),
encrypted: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
uploaded: jspb.Message.getFieldWithDefault(msg, 9, 0),
deleted: jspb.Message.getFieldWithDefault(msg, 22, 0),
etag: jspb.Message.getFieldWithDefault(msg, 10, ""),
bucketType: jspb.Message.getFieldWithDefault(msg, 11, 0),
userId: jspb.Message.getFieldWithDefault(msg, 16, ""),
organizationId: jspb.Message.getFieldWithDefault(msg, 17, ""),
vehicleId: jspb.Message.getFieldWithDefault(msg, 12, ""),
dockId: jspb.Message.getFieldWithDefault(msg, 21, ""),
controllerId: jspb.Message.getFieldWithDefault(msg, 24, ""),
accessPointSerial: jspb.Message.getFieldWithDefault(msg, 25, ""),
flightId: jspb.Message.getFieldWithDefault(msg, 13, ""),
mediaCapturedAt: jspb.Message.getFieldWithDefault(msg, 18, 0),
canDownload: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
fileUsage: jspb.Message.getFieldWithDefault(msg, 23, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.DataFilePb}
 */
proto.cloud_api.DataFilePb.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.DataFilePb;
  return proto.cloud_api.DataFilePb.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.DataFilePb} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.DataFilePb}
 */
proto.cloud_api.DataFilePb.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreated(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKind(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSha1(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUploadUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBucket(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilename(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignature(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSize(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEncrypted(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUploaded(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeleted(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setEtag(value);
      break;
    case 11:
      var value = /** @type {!proto.cloud_api.FileStorage.BucketType} */ (reader.readEnum());
      msg.setBucketType(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setDockId(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setControllerId(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessPointSerial(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMediaCapturedAt(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanDownload(value);
      break;
    case 23:
      var value = /** @type {!proto.cloud_api.FileUsage.FileUsageReason} */ (reader.readEnum());
      msg.setFileUsage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.DataFilePb.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.DataFilePb.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.DataFilePb} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.DataFilePb.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreated();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getKind();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSha1();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUploadUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBucket();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getSignature();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getEncrypted();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getUploaded();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getDeleted();
  if (f !== 0) {
    writer.writeInt64(
      22,
      f
    );
  }
  f = message.getEtag();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getBucketType();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getDockId();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getControllerId();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getAccessPointSerial();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getMediaCapturedAt();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getCanDownload();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getFileUsage();
  if (f !== 0.0) {
    writer.writeEnum(
      23,
      f
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.cloud_api.DataFilePb.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.DataFilePb} returns this
 */
proto.cloud_api.DataFilePb.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 created = 14;
 * @return {number}
 */
proto.cloud_api.DataFilePb.prototype.getCreated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_api.DataFilePb} returns this
 */
proto.cloud_api.DataFilePb.prototype.setCreated = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string kind = 2;
 * @return {string}
 */
proto.cloud_api.DataFilePb.prototype.getKind = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.DataFilePb} returns this
 */
proto.cloud_api.DataFilePb.prototype.setKind = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sha1 = 3;
 * @return {string}
 */
proto.cloud_api.DataFilePb.prototype.getSha1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.DataFilePb} returns this
 */
proto.cloud_api.DataFilePb.prototype.setSha1 = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string upload_url = 4;
 * @return {string}
 */
proto.cloud_api.DataFilePb.prototype.getUploadUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.DataFilePb} returns this
 */
proto.cloud_api.DataFilePb.prototype.setUploadUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string bucket = 5;
 * @return {string}
 */
proto.cloud_api.DataFilePb.prototype.getBucket = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.DataFilePb} returns this
 */
proto.cloud_api.DataFilePb.prototype.setBucket = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string key = 6;
 * @return {string}
 */
proto.cloud_api.DataFilePb.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.DataFilePb} returns this
 */
proto.cloud_api.DataFilePb.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string filename = 19;
 * @return {string}
 */
proto.cloud_api.DataFilePb.prototype.getFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.DataFilePb} returns this
 */
proto.cloud_api.DataFilePb.prototype.setFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string signature = 7;
 * @return {string}
 */
proto.cloud_api.DataFilePb.prototype.getSignature = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.DataFilePb} returns this
 */
proto.cloud_api.DataFilePb.prototype.setSignature = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 size = 8;
 * @return {number}
 */
proto.cloud_api.DataFilePb.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_api.DataFilePb} returns this
 */
proto.cloud_api.DataFilePb.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool encrypted = 15;
 * @return {boolean}
 */
proto.cloud_api.DataFilePb.prototype.getEncrypted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.DataFilePb} returns this
 */
proto.cloud_api.DataFilePb.prototype.setEncrypted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional int64 uploaded = 9;
 * @return {number}
 */
proto.cloud_api.DataFilePb.prototype.getUploaded = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_api.DataFilePb} returns this
 */
proto.cloud_api.DataFilePb.prototype.setUploaded = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 deleted = 22;
 * @return {number}
 */
proto.cloud_api.DataFilePb.prototype.getDeleted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_api.DataFilePb} returns this
 */
proto.cloud_api.DataFilePb.prototype.setDeleted = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional string etag = 10;
 * @return {string}
 */
proto.cloud_api.DataFilePb.prototype.getEtag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.DataFilePb} returns this
 */
proto.cloud_api.DataFilePb.prototype.setEtag = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional FileStorage.BucketType bucket_type = 11;
 * @return {!proto.cloud_api.FileStorage.BucketType}
 */
proto.cloud_api.DataFilePb.prototype.getBucketType = function() {
  return /** @type {!proto.cloud_api.FileStorage.BucketType} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.cloud_api.FileStorage.BucketType} value
 * @return {!proto.cloud_api.DataFilePb} returns this
 */
proto.cloud_api.DataFilePb.prototype.setBucketType = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional string user_id = 16;
 * @return {string}
 */
proto.cloud_api.DataFilePb.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.DataFilePb} returns this
 */
proto.cloud_api.DataFilePb.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string organization_id = 17;
 * @return {string}
 */
proto.cloud_api.DataFilePb.prototype.getOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.DataFilePb} returns this
 */
proto.cloud_api.DataFilePb.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string vehicle_id = 12;
 * @return {string}
 */
proto.cloud_api.DataFilePb.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.DataFilePb} returns this
 */
proto.cloud_api.DataFilePb.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string dock_id = 21;
 * @return {string}
 */
proto.cloud_api.DataFilePb.prototype.getDockId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.DataFilePb} returns this
 */
proto.cloud_api.DataFilePb.prototype.setDockId = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string controller_id = 24;
 * @return {string}
 */
proto.cloud_api.DataFilePb.prototype.getControllerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.DataFilePb} returns this
 */
proto.cloud_api.DataFilePb.prototype.setControllerId = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string access_point_serial = 25;
 * @return {string}
 */
proto.cloud_api.DataFilePb.prototype.getAccessPointSerial = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.DataFilePb} returns this
 */
proto.cloud_api.DataFilePb.prototype.setAccessPointSerial = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string flight_id = 13;
 * @return {string}
 */
proto.cloud_api.DataFilePb.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.DataFilePb} returns this
 */
proto.cloud_api.DataFilePb.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int64 media_captured_at = 18;
 * @return {number}
 */
proto.cloud_api.DataFilePb.prototype.getMediaCapturedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_api.DataFilePb} returns this
 */
proto.cloud_api.DataFilePb.prototype.setMediaCapturedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional bool can_download = 20;
 * @return {boolean}
 */
proto.cloud_api.DataFilePb.prototype.getCanDownload = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.DataFilePb} returns this
 */
proto.cloud_api.DataFilePb.prototype.setCanDownload = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional FileUsage.FileUsageReason file_usage = 23;
 * @return {!proto.cloud_api.FileUsage.FileUsageReason}
 */
proto.cloud_api.DataFilePb.prototype.getFileUsage = function() {
  return /** @type {!proto.cloud_api.FileUsage.FileUsageReason} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {!proto.cloud_api.FileUsage.FileUsageReason} value
 * @return {!proto.cloud_api.DataFilePb} returns this
 */
proto.cloud_api.DataFilePb.prototype.setFileUsage = function(value) {
  return jspb.Message.setProto3EnumField(this, 23, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.DataFiles.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.DataFiles.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.DataFiles.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.DataFiles} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.DataFiles.toObject = function(includeInstance, msg) {
  var f, obj = {
dataFilesList: jspb.Message.toObjectList(msg.getDataFilesList(),
    proto.cloud_api.DataFilePb.toObject, includeInstance),
pagination: (f = msg.getPagination()) && pbtypes_tools_cloud_api_pagination_pb.Pagination.toObject(includeInstance, f),
fileUuidPublicUrlsMap: (f = msg.getFileUuidPublicUrlsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.DataFiles}
 */
proto.cloud_api.DataFiles.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.DataFiles;
  return proto.cloud_api.DataFiles.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.DataFiles} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.DataFiles}
 */
proto.cloud_api.DataFiles.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cloud_api.DataFilePb;
      reader.readMessage(value,proto.cloud_api.DataFilePb.deserializeBinaryFromReader);
      msg.addDataFiles(value);
      break;
    case 2:
      var value = new pbtypes_tools_cloud_api_pagination_pb.Pagination;
      reader.readMessage(value,pbtypes_tools_cloud_api_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 3:
      var value = msg.getFileUuidPublicUrlsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.DataFiles.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.DataFiles.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.DataFiles} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.DataFiles.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataFilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cloud_api.DataFilePb.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_tools_cloud_api_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getFileUuidPublicUrlsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * repeated DataFilePb data_files = 1;
 * @return {!Array<!proto.cloud_api.DataFilePb>}
 */
proto.cloud_api.DataFiles.prototype.getDataFilesList = function() {
  return /** @type{!Array<!proto.cloud_api.DataFilePb>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cloud_api.DataFilePb, 1));
};


/**
 * @param {!Array<!proto.cloud_api.DataFilePb>} value
 * @return {!proto.cloud_api.DataFiles} returns this
*/
proto.cloud_api.DataFiles.prototype.setDataFilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cloud_api.DataFilePb=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.DataFilePb}
 */
proto.cloud_api.DataFiles.prototype.addDataFiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cloud_api.DataFilePb, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.DataFiles} returns this
 */
proto.cloud_api.DataFiles.prototype.clearDataFilesList = function() {
  return this.setDataFilesList([]);
};


/**
 * optional Pagination pagination = 2;
 * @return {?proto.cloud_api.Pagination}
 */
proto.cloud_api.DataFiles.prototype.getPagination = function() {
  return /** @type{?proto.cloud_api.Pagination} */ (
    jspb.Message.getWrapperField(this, pbtypes_tools_cloud_api_pagination_pb.Pagination, 2));
};


/**
 * @param {?proto.cloud_api.Pagination|undefined} value
 * @return {!proto.cloud_api.DataFiles} returns this
*/
proto.cloud_api.DataFiles.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.DataFiles} returns this
 */
proto.cloud_api.DataFiles.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.DataFiles.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * map<string, string> file_uuid_public_urls = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.cloud_api.DataFiles.prototype.getFileUuidPublicUrlsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.cloud_api.DataFiles} returns this
 */
proto.cloud_api.DataFiles.prototype.clearFileUuidPublicUrlsMap = function() {
  this.getFileUuidPublicUrlsMap().clear();
  return this;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.GetFileKindsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.GetFileKindsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.GetFileKindsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.GetFileKindsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.GetFileKindsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
kindsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.GetFileKindsResponse}
 */
proto.cloud_api.GetFileKindsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.GetFileKindsResponse;
  return proto.cloud_api.GetFileKindsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.GetFileKindsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.GetFileKindsResponse}
 */
proto.cloud_api.GetFileKindsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addKinds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.GetFileKindsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.GetFileKindsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.GetFileKindsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.GetFileKindsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKindsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string kinds = 1;
 * @return {!Array<string>}
 */
proto.cloud_api.GetFileKindsResponse.prototype.getKindsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cloud_api.GetFileKindsResponse} returns this
 */
proto.cloud_api.GetFileKindsResponse.prototype.setKindsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.GetFileKindsResponse} returns this
 */
proto.cloud_api.GetFileKindsResponse.prototype.addKinds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.GetFileKindsResponse} returns this
 */
proto.cloud_api.GetFileKindsResponse.prototype.clearKindsList = function() {
  return this.setKindsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.DownloadFilesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.DownloadFilesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.DownloadFilesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.DownloadFilesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.DownloadFilesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
fileUuidsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.DownloadFilesRequest}
 */
proto.cloud_api.DownloadFilesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.DownloadFilesRequest;
  return proto.cloud_api.DownloadFilesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.DownloadFilesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.DownloadFilesRequest}
 */
proto.cloud_api.DownloadFilesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addFileUuids(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.DownloadFilesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.DownloadFilesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.DownloadFilesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.DownloadFilesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileUuidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string file_uuids = 1;
 * @return {!Array<string>}
 */
proto.cloud_api.DownloadFilesRequest.prototype.getFileUuidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cloud_api.DownloadFilesRequest} returns this
 */
proto.cloud_api.DownloadFilesRequest.prototype.setFileUuidsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.DownloadFilesRequest} returns this
 */
proto.cloud_api.DownloadFilesRequest.prototype.addFileUuids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.DownloadFilesRequest} returns this
 */
proto.cloud_api.DownloadFilesRequest.prototype.clearFileUuidsList = function() {
  return this.setFileUuidsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.AddMediaMetadataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.AddMediaMetadataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.AddMediaMetadataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.AddMediaMetadataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
fileId: jspb.Message.getFieldWithDefault(msg, 1, ""),
metadataJson: jspb.Message.getFieldWithDefault(msg, 2, ""),
hasThumbnail: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.AddMediaMetadataRequest}
 */
proto.cloud_api.AddMediaMetadataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.AddMediaMetadataRequest;
  return proto.cloud_api.AddMediaMetadataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.AddMediaMetadataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.AddMediaMetadataRequest}
 */
proto.cloud_api.AddMediaMetadataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMetadataJson(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasThumbnail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.AddMediaMetadataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.AddMediaMetadataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.AddMediaMetadataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.AddMediaMetadataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMetadataJson();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHasThumbnail();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string file_id = 1;
 * @return {string}
 */
proto.cloud_api.AddMediaMetadataRequest.prototype.getFileId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.AddMediaMetadataRequest} returns this
 */
proto.cloud_api.AddMediaMetadataRequest.prototype.setFileId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string metadata_json = 2;
 * @return {string}
 */
proto.cloud_api.AddMediaMetadataRequest.prototype.getMetadataJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.AddMediaMetadataRequest} returns this
 */
proto.cloud_api.AddMediaMetadataRequest.prototype.setMetadataJson = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool has_thumbnail = 3;
 * @return {boolean}
 */
proto.cloud_api.AddMediaMetadataRequest.prototype.getHasThumbnail = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.AddMediaMetadataRequest} returns this
 */
proto.cloud_api.AddMediaMetadataRequest.prototype.setHasThumbnail = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.VehicleDownloadFileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.VehicleDownloadFileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.VehicleDownloadFileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.VehicleDownloadFileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
fileUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
type: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.VehicleDownloadFileRequest}
 */
proto.cloud_api.VehicleDownloadFileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.VehicleDownloadFileRequest;
  return proto.cloud_api.VehicleDownloadFileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.VehicleDownloadFileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.VehicleDownloadFileRequest}
 */
proto.cloud_api.VehicleDownloadFileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileUuid(value);
      break;
    case 2:
      var value = /** @type {!proto.cloud_api.VehicleDownloadFileRequest.FileType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.VehicleDownloadFileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.VehicleDownloadFileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.VehicleDownloadFileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.VehicleDownloadFileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.cloud_api.VehicleDownloadFileRequest.FileType = {
  UNKNOWN: 0,
  FLIGHT: 1,
  DEVICE: 2,
  ORGANIZATION: 3
};

/**
 * optional string file_uuid = 1;
 * @return {string}
 */
proto.cloud_api.VehicleDownloadFileRequest.prototype.getFileUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.VehicleDownloadFileRequest} returns this
 */
proto.cloud_api.VehicleDownloadFileRequest.prototype.setFileUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional FileType type = 2;
 * @return {!proto.cloud_api.VehicleDownloadFileRequest.FileType}
 */
proto.cloud_api.VehicleDownloadFileRequest.prototype.getType = function() {
  return /** @type {!proto.cloud_api.VehicleDownloadFileRequest.FileType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.cloud_api.VehicleDownloadFileRequest.FileType} value
 * @return {!proto.cloud_api.VehicleDownloadFileRequest} returns this
 */
proto.cloud_api.VehicleDownloadFileRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


goog.object.extend(exports, proto.cloud_api);
