import jspb from "google-protobuf";
import any_pb from "google-protobuf/google/protobuf/any_pb";
import queryString from "query-string";

import { camelKeys, snakeKeys, StringConverter, convertKeys } from "@skydio/common_util/src/convertKeys";

export type APIMethod = "DELETE" | "GET" | "POST";
export interface PathParams {
  [key: string]: string;
}

export type APIPath = string | ((params: PathParams) => string);
export interface APIRoute<_P, R> {
  pathSpec: APIPath;
  method: APIMethod;
  // should pass in the .deserializeBinary of the protobuf message return type
  protobufDeserializer?: (bytes: Uint8Array) => R;
}

export interface RequestJSONBodyParams {
  [key: string]: any;
}

export interface RequestProtobufBodyParams {
  protobuf?: jspb.Message;
}

// TODO(huarui, sam): I don't love that for json bodies the top-level keys in RequestParams are
// the json itself, such that we can't ever use 'path' as a json key... I think it should be
// adjusted such that the json-body is nested similar to the protobuf one, so the top interface
// looks like: { path: PathParams, json?: RequestJSONBodyParams, protobuf?: jspb.Message }
export interface RequestParams extends RequestJSONBodyParams, RequestProtobufBodyParams {
  // If the request type specifies a 'path' object then the object is used to populate the
  // request path args and not actually sent in the body of the request
  path?: PathParams;
}

export class FetchError extends Error {
  code?: string; // Needs to be a string to be compatible with redux-toolkit SerializedError
  response?: Response;
}

// Use this to define an endpoint (a url/method combination)
// Each endpoint is parameterized with its request parameters and expected response type
// e.g. createRoute<RequestType, ReturnType>('some/url', 'GET')
// NOTE: undefined for no request, void for no response
export function createRoute<P extends RequestParams | undefined, R extends any>(
  pathSpec: APIPath,
  method: APIMethod
): APIRoute<P, R> {
  return { pathSpec, method };
}

export function createProtobufRoute<P extends RequestParams | undefined, R>(
  pathSpec: APIPath,
  method: APIMethod,
  // If this is a protobuf route the return protobuf class must be passed through so we can use
  // it to populate the response
  protobufDeserializer: (bytes: Uint8Array) => R
): APIRoute<P, R> {
  return {
    pathSpec,
    method,
    protobufDeserializer,
  };
}

// Creates a function for sending API requests to a particular backend.
// Accepts a function that will perform the actual network fetching, which should expect the same
// arguments as the browser fetch method - this function should also perform any auth or error
// handling logic specific to that backend (and throw any appropriate errors).
// The returned `sendRequest` function can take a properly parameterized APIRoute and a payload and
// will construct the url/init (passed along to the fetching function) and infer
// request/response types appropriately.
// By default, keys in a JSON response body will be converted to camelCase and keys in a JSON
// request will be converted to snake_case to match respective language conventions. To modify this
// behavior, convertResponseKeys and/or convertRequestKeys can be set to false to disable case
// conversion, or can be passed an alternative case conversion function to use instead.
export function makeSendRequest(
  fetchFunc: (url: string, init: RequestInit) => Promise<any>,
  urlPrefix: string,
  convertResponseKeys: boolean | StringConverter = true,
  convertRequestKeys: boolean | StringConverter = true
) {
  return async function sendRequest<P extends RequestParams | undefined, R extends any>(
    { pathSpec, method, protobufDeserializer }: APIRoute<P, R>,
    params?: P,
    options: Omit<RequestInit, "method" | "body"> = {}
  ): Promise<R> {
    const { path: pathParams = {}, protobuf, ...bodyParams } = (params || {}) as RequestParams;

    const isGet = method === "GET";

    // the url for this api request is either a string or a function that takes in path
    // parameters from the pathParams to create a string
    let url = typeof pathSpec === "function" ? pathSpec(pathParams) : pathSpec;
    url = `${urlPrefix}/${url}`;

    let payload: string;
    let body: BodyInit | undefined = undefined;
    let headers = options.headers || {};

    if (isGet) {
      // create a query string and append to the url as needed
      if (typeof convertRequestKeys === "function") {
        payload = queryString.stringify(convertKeys(bodyParams, convertRequestKeys));
      } else {
        payload = queryString.stringify(convertRequestKeys ? snakeKeys(bodyParams) : bodyParams);
      }
      if (payload) {
        url = `${url}?${payload}`;
      }
    } else if (protobuf !== undefined) {
      // serialize the protobuf request message to bytes
      headers = {
        "Content-Type": "application/x-protobuf",
        ...headers,
      };
      body = protobuf.serializeBinary();
    } else {
      // this is likely a POST message with a json body
      headers = {
        "Content-Type": "application/json",
        ...headers,
      };
      if (typeof convertRequestKeys === "function") {
        body = JSON.stringify(convertKeys(bodyParams, convertRequestKeys));
      } else {
        body = JSON.stringify(convertRequestKeys ? snakeKeys(bodyParams) : bodyParams);
      }
    }

    if (protobufDeserializer !== undefined) {
      headers = {
        Accept: "application/x-protobuf",
        ...headers,
      };
    }

    const data = await fetchFunc(url, {
      ...options,
      method,
      headers,
      body,
    });

    // if the returned value is a protobuf let's init and return that message from the response
    if (protobufDeserializer !== undefined) {
      if (data instanceof any_pb.Any) {
        return protobufDeserializer(data.getValue_asU8());
      } else if (data instanceof Uint8Array) {
        return protobufDeserializer(data);
      }
    }

    if (typeof convertResponseKeys === "function") {
      return convertKeys(data, convertResponseKeys) as R;
    }
    return (convertResponseKeys ? camelKeys(data) : data) as R;
  };
}

export function download(url: string, downloadFilename?: string) {
  let element = document.createElement("a");
  element.setAttribute("href", url);
  element.setAttribute("download", downloadFilename || "true");

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}
